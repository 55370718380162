import React from 'react'

const About = () => {
  return (
    <div>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse quos sunt, similique harum itaque officia nostrum, 
        odit eveniet hic quibusdam dolorum sapiente temporibus perspiciatis? Voluptate fugit modi ex iusto rem reprehenderit 
        sit ipsam, delectus saepe ea quo dicta sint vitae, quasi sequi placeat aliquid exercitationem!
         Est dolores unde, quia reiciendis esse maxime placeat? Sint qui aliquid eum libero temporibus maxime.</p>
    </div>
  )
}

export default About